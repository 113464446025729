<template>
  <div class="certificate-module-container" v-if="canEdit">
    <div class="title certificate-header">
      Assinatura digital do prontuário
    </div>

    <input type="hidden" id="certThumbField" name="certThumb" />
    <input type="hidden" id="certContentField" name="certContent" />

    <div>
      <div v-if="certAuth && certAuth.active === 'inative'" class="certificate-content">
        <CertificateUnchecked class="icon"/>
        <span class="no-certificate">
          <strong>Assinatura digital não configurada</strong>
          <br />
          Configure o seu certificado digital para assinar digitalmente
          seus prontuários.
        </span>
        <span
          style="
            float: right;
            cursor: pointer;
            margin-top: 1.5px;
            font-family: Nunito Sans;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 150%;
            align-items: center;
            text-align: right;
            color: #305bf2;
          "
          v-b-modal.signature
        >
          Configurar
        </span>
      </div>
      <span v-else>
        <div
          v-if="certAuth && certAuth.active == true"
          style="
            background: #dbfff0;
            border-radius: 8px;
            flex-direction: row;
            align-items: center;
            padding: 16px 24px;
            margin-right: 20px;
          "
        >
          <CertificateChecked class="icon" />
          <span v-if="certAuth.type == 1" style="font-size: 15px">
            {{
              certAuth.cert.subjectName +
              ' -  ' +
              certAuth.cert.issuerName +
              ' - ' +
              certAuth.cert.validityEnd
            }}
          </span>
          <span
            v-if="certAuth.type == 2"
            style="font-size: 15px"
          >
            {{
              certAuth.cert.name + '(' + certAuth.cert.provider + ')'
            }}
          </span>
          <span
            style="
              float: right;
              cursor: pointer;
              margin-top: 1.5px;
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
              align-items: center;
              text-align: right;
              color: #305bf2;
            "
            v-b-modal.signature
          >
            Trocar Certificado
          </span>
        </div>
        <div
          v-if="certAuth && certAuth.active == false"
          style="
            flex-direction: row;
            align-items: center;
            display: flex;
            justify-content: space-between;
            background: #fff0e5;
            border-radius: 8px;
            padding: 16px 20px;
            margin-right: 5px;
          "
        >
          <div style="
            display: flex;
            flex-direction: row;
            align-items: center;
          ">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#B34B00"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 7V13.5"
                stroke="#B34B00"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 17H12.01"
                stroke="#B34B00"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <div
              style="
                display: flex;
                flex-direction: column;
                margin-left: 15px;
                font-family: Nunito Sans;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 150%;
                color: #525c7a;
              "
              >
              <div>
                <strong>Assinatura digital desligada</strong>
              </div>
              <div>
                Para concluir o prontuário é necessário estar com a
                assinatura digital ligada.
              </div>
            </div>
          </div>
          <div
            style="
              cursor: pointer;
              font-family: Nunito Sans;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              text-align: right;
              color: #305bf2;
            "
            v-b-modal.signature
          >
            Ativar assinatura digital
          </div>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import CertificateUnchecked from '@/assets/icons/certificate-unchecked.svg'
import CertificateChecked from '@/assets/icons/certificate-checked.svg'

export default {
  components: { CertificateUnchecked, CertificateChecked },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
    })
  },
  data() {
    return {
      certAuth: null,
    }
  },
  mounted() {
    this.setCertificate()
  },
  methods: {
    setCertificate() {
      let auxCert = JSON.parse(localStorage.getItem('singItemAutentic'))
      if (auxCert !== undefined && auxCert !== null) {
        const date = new Date(auxCert.cert.validityEnd)
        auxCert.cert.validityEnd =
          addZero(date.getDate().toString()) +
          '/' +
          addZero(date.getMonth() + 1).toString() +
          '/' +
          date.getFullYear()
      } else {
        auxCert = { active: 'inative' }
      }
      this.certAuth = auxCert

      function addZero(number) {
        if (number <= 9) return '0' + number
        else return number
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.certificate-module-container {
  margin-top: 38px;
  padding: 16px;
}
.certificate-header {
  text-align: left;
  font-size: 19px;
}
.certificate-content {
  background: #fff0e5;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  padding: 16px 17px;
}
.no-certificate {
  display: inline-table;
  margin-left: 15px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #525c7a;
}

</style>
